import { Channel } from '../../models/dto/channel';
import { Network } from '../../models/ui/network';

export const networksMapper = (channel: Channel): Network[] => {
  const networks: Network[] = [];

  if (channel?.facebook) {
    networks.push({
      label: 'Facebook',
      url: channel?.facebook,
      svg: 'facebook.svg',
    });
  }

  if (channel?.twitter) {
    networks.push({
      label: 'Twitter',
      url: channel?.twitter,
      svg: 'twitter.svg',
    });
  }

  if (channel?.instagram) {
    networks.push({
      label: 'Instagram',
      url: channel?.instagram,
      svg: 'instagram.svg',
    });
  }

  if (channel?.vimeo) {
    networks.push({
      label: 'Vimeo',
      url: channel?.vimeo,
      svg: 'vimeo.svg',
    });
  }

  if (channel?.youtube) {
    networks.push({
      label: 'Youtube',
      url: channel?.youtube,
      svg: 'youtube.svg',
    });
  }

  if (channel?.strava) {
    networks.push({
      label: 'Strava',
      url: channel?.strava,
      svg: 'strava.svg',
    });
  }

  if (channel?.linkedin) {
    networks.push({
      label: 'LinkedIn',
      url: channel?.linkedin,
      svg: 'linkedin.svg',
    });
  }

  return networks;
};
