import { environment } from 'src/environments/environment';

export const normalizeSlug = (slug: string): string => {
  return slug.replace(/[/]/g, '@'); // We replace '/' with '@' (slugs shouldn't contain slashes)
};

export const normalizeArrayParam = (
  param: undefined | string | string[]
): string[] => {
  if (param !== undefined) {
    return Array.isArray(param) ? param : [param];
  } else {
    return [];
  }
};

export const targetApi = (url: string): boolean => {
  return url.startsWith(environment.apiProtocol + environment.apiHost);
};

export const targetApp = (url: string): boolean => {
  return (
    url.startsWith(environment.baseUrl) ||
    !!(
      environment.debug &&
      (url.startsWith('https://www.mavic.com') ||
        url.startsWith('https://mavic-frontend.votre-projet.com'))
    )
  );
};

export const internalLink = (value?: string): string => {
  let link: string = '';
  if (value) {
    try {
      const url: URL = new URL(value);
      if (targetApp(value)) {
        link = url.pathname;
      }
    } catch (e: unknown) {
      // If we catch an error this mean the value wasn't a proper URL format so we default it to a relative route
      link = value;
      if (!link.startsWith('/')) {
        link = '/' + link;
      }
    }
  }

  return link;
};
